.grid {
  display: grid;
  grid-auto-rows: 10vh;
  gap: 10px;
  grid-template-columns: repeat(12, 1fr);
  width: 100%;
}

.grid2Rows {
  grid-template-rows: repeat(2, 10vh);
  gap: 10px;
}

.grid3Rows {
  gap: 10px;
  grid-template-rows: repeat(3, 10vh);
}

.grid4Rows {
  gap: 10px;
  grid-template-rows: repeat(4, 10vh);
}

.grid5Rows {
  gap: 10px;
  grid-template-rows: repeat(5, 10vh);
}

.grid6Rows {
  gap: 10px;
  grid-template-rows: repeat(6, 10vh);
}

.grid7Rows {
  gap: 10px;
  grid-template-rows: repeat(7, 10vh);
}

.grid8Rows {
  gap: 10px;
  grid-template-rows: repeat(8, 10vh);
}

.grid9Rows {
  gap: 10px;
  grid-template-rows: repeat(9, 10vh);
}

.grid8LandingRows {
  gap: 10px;
  grid-template-rows: repeat(8, 10vh);
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .grid2Rows,
  .grid3Rows,
  .grid4Rows,
  .grid5Rows,
  .grid6Rows,
  .grid7Rows,
  .grid8Rows,
  .grid9Rows {
    gap: 10px;
    grid-template-rows: repeat(auto, 10vh);
  }
}
