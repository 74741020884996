.landing-box {
  height: 100%;
  width: 100%;
}

.landing-intro {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .landing-intro {
    align-items: center;
  }

  .landing-navbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
}
