.proposal {
  background-color: #e5e5e5;
  position: relative;
  z-index: 2;
}

.proposal-items-wrapper {
  align-items: flex-start;
  display: flex;
  justify-content: space-evenly;
  grid-column: 2 / 12;
  grid-row: 2 / 5;
}

.proposal-item-box {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 25%;
}

.proposal-item-number {
  align-items: center;
  background-image: linear-gradient(to right, #2dc9c5 0%, #419efe 100%);
  background-size: 100%;
  border: 3px solid #fff;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 2rem;
  height: 60px;
  justify-content: center;
  width: 60px;
}

.proposal-text-wrapper {
  align-items: center;
  display: flex;
  grid-column: 2 / 12;
  grid-row: 5 / 6;
  margin-top: 50px;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .proposal-items-wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    grid-row: 1 / 11;
    justify-content: center;
  }

  .proposal-item-box {
    margin: 20px 0;
    width: 100%;
  }

  .proposal-text-wrapper {
    display: none;
  }
}
