.colorButton {
  background-size: 200% auto;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.8vh;
  letter-spacing: 1px;
  margin: 10px;
  padding: 0.8rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
}

.colorButton:hover {
  background-position: right center;
}

.colorButtonRed {
  background-image: linear-gradient(to right, #f52e13 0%, #ff4747 100%);
}

.colorButtonBlue {
  background-image: linear-gradient(to right, #2dc9c5 0%, #419efe 100%);
}
