.methodology {
  background-image: linear-gradient(to right bottom, #419efe 0%, #2dc9c5 100%);
  background-size: 100%;
  position: relative;
  width: 100%;
}

.methodology-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 2 / 12;
  grid-row: 1 / 5;
  justify-content: center;
}

.methodologies-box {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.methodology-item {
  box-sizing: border-box;
  border-radius: 25px;
  border: 2px solid #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 20px 0px 20px;
  width: 20%;
}

.methodology-image {
  width: 60px;
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .methodologies-box {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .methodology-item {
    margin: 10px 0;
    padding: 20px 15px 15px 15px;
    width: 90%;
  }

  .methodology-wrapper {
    grid-row: 1 / 11;
  }
}
