.testimonials {
  background-color: #303030;
  position: relative;
  width: 100%;
}

.testimonials-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 2 / 12;
  grid-row: 1 / 5;
  justify-content: center;
}

.testimonials-box {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.testimonial {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 28%;
}

.testimonial-stars-wrapper {
  display: flex;
  justify-content: center;
}

.testimonial-star {
  width: 35px;
  margin: 0 5px;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .testimonials-wrapper {
    grid-row: 1 / 13;
  }

  .testimonials-box {
    flex-direction: column;
    align-items: center;
  }

  .testimonial {
    margin: 20px 0;
    width: 90%;
  }
}
