.footer {
  background-color: #303030;
  position: relative;
  width: 100%;
}

.footer-rights-reserved {
  align-items: center;
  display: flex;
  grid-column: 2 / 6;
  justify-content: flex-start;
}

.footer-social-nav {
  align-items: center;
  display: flex;
  justify-content: center;
  grid-column: 6 / 8;
}

.footer-social-button {
  background: none;
  cursor: pointer;
  height: 35px;
  margin: 0 10px;
  width: 35px;
}

.footer-social-icon {
  width: 100%;
}

.footer-repse-registry {
  align-items: center;
  display: flex;
  grid-column: 8 / 12;
  justify-content: flex-end;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .footer-social-nav {
    grid-column: 2 / 12;
    grid-row: 1 / 2;
    justify-content: center;
  }

  .footer-rights-reserved {
    grid-column: 2 / 12;
    grid-row: 2 / 3;
    justify-content: center;
  }

  .footer-repse-registry {
    grid-column: 2 / 12;
    grid-row: 3 / 4;
    justify-content: center;
  }
}
