.landing {
  background-color: #f5f5f5;
  margin-top: 9.5vh;
  z-index: 1;
}

.landing-wrapper {
  position: fixed;
}

.landing-bg-banner-box {
  grid-column: 1 / 13;
  overflow: hidden;
  width: 100%;
}

.landing-bg-banner-box-1 {
  grid-row: 1 / 5;
}

.landing-bg-banner-box-2 {
  grid-row: 5 / 9;
}

.landing-transparency {
  background-color: rgba(0, 0, 0, 0.6);
  grid-column: 1 / 13;
  grid-row: 1 / 9;
}

.landing-intro-wrapper {
  grid-column: 2 / 10;
  grid-row: 3 / 8;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .landing-bg-banner-box {
    display: none;
  }

  .landing-bg-banner-box-1 {
    display: none;
  }

  .landing-bg-banner-box-2 {
    display: none;
  }

  .landing-transparency {
    grid-row: 1 / 9;
  }

  .landing {
    background-attachment: fixed;
    background-color: rgba(0, 0, 0, 0);
    background-image: url("../../../../img//landing-bg5.jpg");
    background-position-y: center;
    background-position-x: right;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .landing-intro-wrapper {
    grid-column: 2 / 12;
    grid-row: 2 / 8;
  }
}
