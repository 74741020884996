.owned-certificates {
  background-color: #fff;
  position: relative;
  width: 100%;
}

.certificates-title {
  align-items: center;
  display: flex;
  grid-column: 2 / 12;
  grid-row: 1 / 2;
  justify-content: center;
}

.certificate-wrapper {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-row: 2 / 5;
  padding-top: 30px;
}

.certificate-wrapper-1 {
  grid-column: 3 / 7;
  padding-right: 30px;
}

.certificate-wrapper-2 {
  grid-column: 7 / 11;
  padding-left: 30px;
}

.certificate-divider {
  border: 1px solid #000;
  margin: 20px 0;
  width: 70%;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .certificate-wrapper {
    justify-content: center;
    padding-top: 0;
  }

  .certificate-wrapper-1 {
    grid-column: 2 / 12;
    grid-row: 2 / 5;
    padding-right: 0;
  }

  .certificate-wrapper-2 {
    grid-column: 2 / 12;
    grid-row: 5 / 8;
    padding-left: 0;
  }
}
