.about-us-intro {
  background-attachment: fixed;
  background-color: #e5e5e5;
  background-image: url("../../../../img/about-intro-bg.jpg");
  background-position-y: center;
  background-position-x: right;
  background-repeat: no-repeat;
  background-size: contain;
  position: relative;
  width: 100%;
}

.about-us-presentation-box {
  background-color: #303030;
  grid-column: 1 / 9;
  grid-row: 1 / 5;
}

.about-us-presentation {
  display: flex;
  flex-direction: column;
  grid-column: 2 / 8;
  grid-row: 1 / 5;
  justify-content: center;
  padding-right: 50px;
}

.about-us-presentation-logo {
  width: 200px;
}

.about-us-culture-wrapper {
  background-color: #e5e5e5;
  grid-column: 1 / 13;
  grid-row: 1 / 8;
}

.about-us-culture {
  display: flex;
  flex-direction: column;
  grid-column: 2 / 5;
  grid-row: 1 / 8;
  justify-content: center;
}

.about-us-cuture-divider {
  border: 1px solid #000;
  width: 70%;
}

.about-us-diagram-title {
  grid-column: 6 / 12;
  grid-row: 1 / 2;
}

.about-us-diagram-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  grid-column: 6 / 12;
  grid-row: 1 / 8;
  justify-content: center;
}

.about-us-diagram {
  border: 2px solid #000;
  box-sizing: border-box;
  padding: 8px;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .about-us-presentation-box {
    background-color: rgba(0, 0, 0, 0.6);
    grid-column: 1 / 13;
    grid-row: 1 / 6;
  }

  .about-us-presentation {
    box-sizing: border-box;
    grid-column: 1 / 13;
    grid-row: 1 / 6;
    padding: 30px;
  }

  .about-us-culture-wrapper {
    grid-row: 1 / 7;
  }

  .about-us-culture {
    box-sizing: border-box;
    grid-column: 1 / 13;
    grid-row: 1 / 7;
    padding: 30px;
  }

  .about-us-diagram-wrapper {
    display: none;
  }
}
