.headerButton {
  color: #000;
  cursor: pointer;
  font-family: "Raleway", sans-serif;
  font-size: 2vh;
  font-weight: bold;
  letter-spacing: 2px;
  text-decoration: none;
  transition: 0.3s;
}

.headerButton:hover {
  text-decoration: underline;
}

@media screen and (min-width: 1px) and (max-width: 999px) {
  .headerButton {
    margin: 8px 0;
    text-align: center;
    width: 100%;
  }
}
