.mission {
  background-attachment: fixed;
  background-image: url("../../../../img/about-mission-bg.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
}

.mission-box {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-row: 1 / 4;
  padding: 30px;
}

.mission-box-1 {
  grid-column: 2 / 7;
}

.mission-box-2 {
  grid-column: 7 / 12;
}

.mission-divider {
  border: 1px solid #fff;
  margin: 30px 0;
  width: 70%;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .mission-box {
    padding: 25px;
  }

  .mission-box-1 {
    grid-column: 2 / 12;
    grid-row: 1 / 4;
  }

  .mission-box-2 {
    grid-column: 2 / 12;
    grid-row: 4 / 8;
  }
}
