.landing-banner-ref {
  height: 100%;
  width: calc(110vw + 20px);
}

.landing-banner {
  display: flex;
  height: 100%;
  width: calc(100vw + 20px);
}

.landing-banner-img-wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.landing-banner-img-wrapper-small {
  width: 30vw;
}

.landing-banner-img-wrapper-big {
  width: 35vw;
}

.landing-banner-img-divider {
  width: 10px;
}

.landing-banner-img {
  width: 100%;
}
