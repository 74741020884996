.header {
  border-bottom: 1px solid #000;
  width: 100%;
  background-color: #f5f5f5;
  position: fixed;
  z-index: 3;
  top: 0;
}

.header-logo-wrapper {
  align-items: center;
  display: flex;
  grid-column: 2 / 4;
  justify-content: flex-start;
}

.header-logo {
  width: 200px;
}

.desktop-header-nav {
  align-items: center;
  display: flex;
  grid-column: 5 / 9;
  justify-content: space-between;
}

.desktop-header-button {
  align-items: center;
  display: flex;
  grid-column: 10 / 12;
  justify-content: flex-end;
}

.mobile-header-button-wrapper {
  display: none;
}

.mobile-menu {
  display: none;
}

.mobile-menu-hidden {
  display: none;
}

.header-redirection-link {
  cursor: pointer;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .desktop-header-nav,
  .desktop-header-button {
    display: none;
  }

  .header-logo-wrapper {
    grid-column: 2 / 7;
  }

  .mobile-header-button-wrapper {
    align-items: center;
    display: flex;
    grid-column: 10 / 12;
    justify-content: flex-end;
  }

  .mobile-header-menu-button {
    background: none;
    cursor: pointer;
    height: 60px;
    width: 60px;
  }

  .mobile-header-menu-icon {
    width: 100%;
  }

  .mobile-menu {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .mobile-menu-hidden {
    display: none;
  }
}
