.service-boxes {
  background-color: #fff;
  position: relative;
  width: 100%;
}

.service-boxes-wrapper {
  display: flex;
  flex-wrap: wrap;
  grid-column: 1 / 13;
  grid-row: 1 / 9;
}

.service-box {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 50%;
  padding: 30px;
  width: 33.33%;
}

.service-box-1 {
  background-image: url("../../../../img/service-1.png");
}

.service-box-2 {
  background-image: url("../../../../img/service-2.png");
}

.service-box-3 {
  background-image: url("../../../../img/service-3.png");
}

.service-box-4 {
  background-image: url("../../../../img/service-4.png");
}

.service-box-5 {
  background-image: url("../../../../img/service-5.png");
}

.service-box-6 {
  background-image: url("../../../../img/service-6.png");
}

.servide-box-title {
  align-items: flex-end;
  display: flex;
  height: 30%;
  width: 100%;
}

.service-divider-wrapper {
  align-items: center;
  display: flex;
  height: 20%;
  width: 100%;
}

.service-divider {
  border: 1px solid #fff;
  width: 60%;
}

.service-box-text {
  align-items: flex-start;
  display: flex;
  height: 50%;
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .service-boxes-wrapper {
    align-items: center;
    flex-direction: column;
    grid-row: 1 / 20;
    justify-content: center;
  }

  .service-box {
    width: 100%;
    height: 16.66%;
    padding: 15px;
  }
}
