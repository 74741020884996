.text {
  font-family: "Montserrat", sans-serif;
  width: 100%;
}

.textLeft {
  text-align: left;
}

.textCenter {
  text-align: center;
}

.textRight {
  text-align: right;
}

.textLight {
  color: #fff;
}

.textDark {
  color: #000;
}

.textAqua {
  color: #2dc9c5;
}

.textBlue {
  color: #419efe;
}

.textTop {
  margin-top: 15px;
}

.textBottom {
  margin-bottom: 15px;
}

.textNormal {
  font-size: 2.2vh;
}

.textHighlight {
  font-size: 2.8vh;
}

.textThin {
  font-weight: 300;
}

.textRegular {
  font-weight: 400;
}

.textSemi {
  font-weight: 600;
}
