.subheadingLeft {
  text-align: left;
}

.subheadingCenter {
  text-align: center;
}

.subheadingRight {
  text-align: right;
}

.subheadingLight {
  color: #fff;
}

.subheadingDark {
  color: #222;
}

.subheadingAqua {
  color: #2dc9c5;
}

.subheadingBlue {
  color: #419efe;
}

.subheadingTop {
  margin-top: 25px;
}

.subheadingBottom {
  margin-bottom: 25px;
}

.subheadingExtra {
  font-size: 5vh;
  letter-spacing: 0.3rem;
}

.subheadingMax {
  font-size: 4vh;
  letter-spacing: 0.22rem;
}

.subheadingMid {
  font-size: 3vh;
  letter-spacing: 0.17rem;
}

.subheadingMin {
  font-size: 2.6vh;
  letter-spacing: 0.12rem;
}

.subheadingPrimary {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

.subheadingSecodary {
  font-family: "Montserrat", sans-serif;
}
