.heading {
  font-family: "Oswald", sans-serif;
  font-weight: 300;
}

.headingLight {
  color: #fff;
}

.headingDark {
  color: #444;
}

.headingAqua {
  color: #2dc9c5;
}

.headingBlue {
  color: #419efe;
}

.headingMax {
  font-size: 3rem;
  letter-spacing: 0.3rem;
}

.headingMid {
  font-size: 2.5rem;
  letter-spacing: 0.25rem;
}

.headingLeft {
  text-align: left;
}

.headingCenter {
  text-align: center;
}

.headingRight {
  text-align: right;
}

.headingTop {
  margin-top: 20px;
}

.headingBottom {
  margin-bottom: 20px;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .headingMax {
    font-size: 2.5rem;
    letter-spacing: 0.3rem;
  }

  .headingMid {
    font-size: 2rem;
    letter-spacing: 0.25rem;
  }
}
