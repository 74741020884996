.characteristics {
  background-image: linear-gradient(to right bottom, #2dc9c5 0%, #419efe 100%);
  background-size: 100%;
  position: relative;
}

.characteristics-heading-wrapper {
  align-items: flex-start;
  display: flex;
  grid-column: 2 / 12;
  grid-row: 2 / 3;
  justify-content: center;
}

.characteristic-box {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-row: 3 / 6;
  padding: 0 10px;
}

.characteristic-box-1 {
  grid-column: 2 / 4;
}

.characteristic-box-2 {
  grid-column: 4 / 6;
}

.characteristic-box-3 {
  grid-column: 6 / 8;
}

.characteristic-box-4 {
  grid-column: 8 / 10;
}

.characteristic-box-5 {
  grid-column: 10 / 12;
}

.characteristic-number {
  align-items: center;
  background-color: #f5f5f5;
  border: 5px solid #2dc9c5;
  border-radius: 50%;
  color: #419efe;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1.8rem;
  height: 50px;
  justify-content: center;
  width: 50px;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .characteristics-heading-wrapper {
    margin-top: 30px;
    grid-row: 1 / 2;
  }

  .characteristic-box {
    grid-column: 2 / 12;
  }

  .characteristic-box-1 {
    grid-row: 2 / 4;
  }

  .characteristic-box-2 {
    grid-row: 4 / 6;
  }

  .characteristic-box-3 {
    grid-row: 6 / 8;
  }

  .characteristic-box-4 {
    grid-row: 8 / 10;
  }

  .characteristic-box-5 {
    grid-row: 10 / 12;
  }
}
