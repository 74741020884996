.contact {
  background-color: #e5e5e5;
  position: relative;
  width: 100%;
}

.contact-data-wrapper {
  align-items: flex-start;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-column: 3 / 7;
  grid-row: 1 / 10;
  justify-content: center;
  padding-right: 50px;
}

.contact-badge {
  box-sizing: border-box;
  margin-top: 20px;
  padding: 2px 0;
  border-bottom: solid 2px #419efe;
  width: 100%;
}

.contact-map {
  border: 1px solid #000;
  height: 250px;
  margin-top: 20px;
  width: 100%;
}

.contact-form {
  align-items: center;
  background-image: linear-gradient(to bottom, #419efe 0%, #2dc9c5 100%);
  background-size: 100%;
  border-radius: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  grid-column: 7 / 11;
  grid-row: 2 / 9;
  justify-content: center;
  padding: 30px;
}

.contact-input {
  border-radius: 25px;
  border: 1px solid #606060;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  margin-top: 30px;
  padding: 6px 15px;
  width: 100%;
}

.contact-textarea {
  resize: none;
}

.contact-button {
  background-image: linear-gradient(to right, #f52e13 0%, #ff4747 100%);
  background-size: 200% auto;
  border-radius: 25px;
  color: #fff;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 1rem;
  letter-spacing: 1px;
  padding: 0.8rem 2rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  margin-top: 30px;
  width: 100%;
}

.contact-button:hover {
  background-position: right center;
}

.form-wrapper {
  width: 100%;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .contact-data-wrapper {
    grid-column: 2 / 12;
    padding: 0;
  }

  .contact-form {
    grid-column: 2 / 12;
    grid-row: 10 / 18;
    margin-bottom: 80px;
  }
}
