.certifications {
  background-color: #fff;
  position: relative;
  width: 100%;
}

.certification-logos {
  align-items: center;
  display: flex;
  grid-column: 2 / 12;
  justify-content: space-evenly;
}

.certification-logo {
  width: 100px;
}

@media screen and (min-width: 1px) and (max-width: 1099px) {
  .certifications {
    display: none;
  }
}
